<template>
  <v-card
    width="100%"
    flat
  >
    <v-row
      no-gutters
      :style="{ height: '12%' }"
    >
      <v-card-title :style="{ borderBottom: '1px solid #ccc', width: '100%', height: '60px', fontSize: '2.5vh' }">
        {{ title }}
      </v-card-title>
    </v-row>
    <v-row
      no-gutters
      :style="{ height: '88%' }"
      class="pt-2"
    >
      <v-col>
        <v-row no-gutters>
          <v-col
            sm="8"
            md="8"
            lg="4"
            :cols="$vssWidth < 600 ? 6 : 8"
            class="d-flex align-center"
          >
            Forma de cobrança dos itens preparo:
          </v-col>
          <v-col
            sm="4"
            md="4"
            lg="2"
            :cols="$vssWidth < 600 ? 6 : 8"
            class="pt-2"
          >
            <v-select
              dense
              outlined
              :items="['Maior valor', 'Proporção']"
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            sm="10"
            md="10"
            lg="4"
            :cols="$vssWidth < 600 ? 10 : 8"
            class="d-flex align-center"
          >
            Utiliza pagamento em dinheiro:
          </v-col>
          <v-col
            sm="2"
            md="2"
            lg="2"
            :cols="$vssWidth < 600 ? 2 : 8"
            class="pt-2"
          >
            <v-row no-gutters>
              <v-switch inset />
            </v-row>
          </v-col>
          <v-col
            sm="10"
            md="10"
            lg="4"
            :cols="$vssWidth < 600 ? 10 : 8"
            class="d-flex align-center"
          >
            Utiliza pagamento em depósito:
          </v-col>
          <v-col
            sm="2"
            md="2"
            lg="1"
            :cols="$vssWidth < 600 ? 2 : 8"
            class="pt-2"
          >
            <v-row no-gutters>
              <v-switch inset />
            </v-row>
          </v-col>
        </v-row>
        <!-- <v-row no-gutters>
          <v-col
                sm="10"
                md="10"
                lg="10"
                :cols="$vssWidth < 600 ? 10 : 8"
                class="d-flex align-center"
              >
                Utiliza pagamento em depósito:
              </v-col>
              <v-col
                sm="2"
                md="2"
                lg="2"
                :cols="$vssWidth < 600 ? 2 : 8"
                class="pt-2"
              >
                <v-row no-gutters>
                  <v-switch inset />
                </v-row>
              </v-col>
        </v-row> -->
        <v-row no-gutters>
          <v-col
            sm="10"
            md="10"
            lg="4"
            :cols="$vssWidth < 600 ? 10 : 8"
            class="d-flex align-center"
          >
            Utiliza pagamento em cheque:
          </v-col>
          <v-col
            sm="2"
            md="2"
            lg="2"
            :cols="$vssWidth < 600 ? 2 : 8"
            class="pt-2"
          >
            <v-row no-gutters>
              <v-switch inset />
            </v-row>
          </v-col>
          <v-col
            sm="10"
            md="10"
            lg="4"
            :cols="$vssWidth < 600 ? 9 : 8"
            class="d-flex align-center"
          >
            Utiliza pagamento em boleto:
          </v-col>          
          <v-col
            sm="1"
            md="1"
            lg="1"
            :cols="$vssWidth < 600 ? 1 : 8"
            class="pt-2"
          >
            <v-row no-gutters>
              <v-switch inset />
            </v-row>
          </v-col>
        </v-row>
        <!-- <v-row no-gutters>
          <v-col
            sm="10"
            md="10"
            lg="10"
            :cols="$vssWidth < 600 ? 9 : 8"
            class="d-flex align-center"
          >
            Utiliza pagamento em boleto:
          </v-col>          
          <v-col
            sm="1"
            md="1"
            lg="1"
            :cols="$vssWidth < 600 ? 1 : 8"
            class="pt-2"
          >
            <v-row no-gutters>
              <v-switch inset />
            </v-row>
          </v-col>          
        </v-row> -->
        <v-row no-gutters>
          <v-col
            sm="10"
            md="10"
            lg="4"
            :cols="$vssWidth < 600 ? 10 : 8"
            class="d-flex align-center"
          >
            Utiliza pagamento em vale troca:
          </v-col>
          <v-col
            sm="2"
            md="2"
            lg="2"
            :cols="$vssWidth < 600 ? 2 : 8"
            class="pt-2"
          >
            <v-row no-gutters>
              <v-switch inset />
            </v-row>
          </v-col>
          <v-col
            sm="10"
            md="10"
            lg="4"
            :cols="$vssWidth < 600 ? 10 : 8"
            class="d-flex align-center"
          >
            Utiliza pagamento em vale presente:
          </v-col>
          <v-col
            sm="2"
            md="2"
            lg="1"
            :cols="$vssWidth < 600 ? 2 : 8"
            class="pt-2"
          >
            <v-row no-gutters>
              <v-switch inset />
            </v-row>
          </v-col>
        </v-row>
        <!-- <v-row no-gutters>
          <v-col
            sm="10"
            md="10"
            lg="10"
            :cols="$vssWidth < 600 ? 10 : 8"
            class="d-flex align-center"
          >
            Utiliza pagamento em vale presente:
          </v-col>
          <v-col
            sm="2"
            md="2"
            lg="2"
            :cols="$vssWidth < 600 ? 2 : 8"
            class="pt-2"
          >
            <v-row no-gutters>
              <v-switch inset />
            </v-row>
          </v-col>
        </v-row> -->
        <v-row no-gutters>
          <v-col
            sm="10"
            md="10"
            lg="4"
            :cols="$vssWidth < 600 ? 10 : 8"
            class="d-flex align-center"
          >
            Utiliza pagamento em vale funcionário:
          </v-col>
          <v-col
            sm="2"
            md="2"
            lg="2"
            :cols="$vssWidth < 600 ? 2 : 8"
            class="pt-2"
          >
            <v-row no-gutters>
              <v-switch inset />
            </v-row>
          </v-col>
          <v-col
            sm="10"
            md="10"
            lg="4"
            :cols="$vssWidth < 600 ? 10 : 8"
            class="d-flex align-center"
          >
            Utiliza pagamento em PIX:
          </v-col>
          <v-col
            sm="2"
            md="2"
            lg="1"
            :cols="$vssWidth < 600 ? 2 : 8"
            class="pt-2"
          >
            <v-row no-gutters>
              <v-switch inset />
            </v-row>
          </v-col>
        </v-row>
        <!-- <v-row no-gutters>
          <v-col
            sm="10"
            md="10"
            lg="11"
            :cols="$vssWidth < 600 ? 10 : 8"
            class="d-flex align-center"
          >
            Utiliza pagamento em PIX:
          </v-col>
          <v-col
            sm="2"
            md="2"
            lg="1"
            :cols="$vssWidth < 600 ? 2 : 8"
            class="pt-2"
          >
            <v-text-field
              value=""
              outlined
              dense
              flat
            />
          </v-col>
        </v-row> -->
        <v-row no-gutters>
          <v-col
            sm="10"
            md="10"
            lg="4"
            :cols="$vssWidth < 600 ? 10 : 8"
            class="d-flex align-center"
          >
            Utiliza crediário próprio:
          </v-col>
          <v-col
            sm="2"
            md="2"
            lg="2"
            :cols="$vssWidth < 600 ? 2 : 8"
            class="pt-2"
          >
            <v-row no-gutters>
              <v-switch inset />
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            sm="10"
            md="10"
            lg="4"
            :cols="$vssWidth < 600 ? 10 : 8"
            class="d-flex align-center"
          >
            Utiliza pagamento convênio:
          </v-col>
          <v-col
            sm="2"
            md="2"
            lg="2"
            :cols="$vssWidth < 600 ? 2 : 8"
            class="pt-2"
          >
            <v-row no-gutters>
              <v-switch inset />
            </v-row>
          </v-col>
        </v-row>
        <v-divider horizontal />
        <v-row no-gutters>
          <v-col
            sm="10"
            md="10"
            lg="4"
            :cols="$vssWidth < 600 ? 10 : 8"
            class="d-flex align-center"
          >
            Utiliza pagamento parcial na venda:
          </v-col>
          <v-col
            sm="2"
            md="2"
            lg="2"
            :cols="$vssWidth < 600 ? 2 : 8"
            class="pt-2"
          >
            <v-row no-gutters>
              <v-switch inset />
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            sm="10"
            md="10"
            lg="4"
            :cols="$vssWidth < 600 ? 10 : 8"
            class="d-flex align-center"
          >
            Percentual mínimo para pagamento parcial:
          </v-col>
          <v-col
            sm="2"
            md="2"
            lg="1"
            :cols="$vssWidth < 600 ? 2 : 8"
            class="pt-2"
          >
            <v-text-field
              label=""
              outlined
              dense
              flat
              suffix="%"
            />            
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {


    }
  },
  watch: {

  },
  mounted() {

  },
  methods: {

  },

}
</script>